export const URLS = {
  product: '/products',
  productBrand: '/product-brands',
  productCategory: '/product-categories',
  productColor: '/product-colors',
  productDiscount: '/product-discounts',
  productGroup: '/product-groups',
  productLabel: '/product-labels',
  productOrder: '/product-orders',
  productSize: '/product-sizes',
  productStyle: '/product-styles',
  productType: '/product-types',
  productWeight: '/product-weights',
};
