import agency from './agency';
import appearance from './appearance';
import comment from './comment';
import dashboard from './dashboard';
import feedback from './feedback';
import language from './language';
import media from './media';
import posts from './posts';
import postType from './postType';
import product from './product';
import rating from './rating';
import seo from './seo';
import settings from './settings';
import user from './user';
import warehouse from './warehouse';
import widget from './widget';

export default {
  ...agency,
  ...appearance,
  ...comment,
  ...dashboard,
  ...feedback,
  ...language,
  ...media,
  ...posts,
  ...postType,
  ...product,
  ...rating,
  ...seo,
  ...settings,
  ...user,
  ...warehouse,
  ...widget,
};
