const brand = {
  'admin.products.brand.helmet': 'Thương hiệu',
  'admin.products.brand.col-left.title': 'Thêm thương hiệu',
  'admin.products.brand.noti.delete.title': 'Xác nhận xoá thương hiệu!',
  'admin.products.brand.noti.delete.description': 'Bạn chắc chắn muốn xoá thương hiệu này?',
  'admin.products.brand.noti.delete.success': 'Xoá thương hiệu thành công!',
  'admin.products.brand.noti.delete.failure': 'Xóa thương hiệu không thành công! Vui lòng thử lại sau.',
};

const category = {
  'admin.products.category.helmet': 'Danh mục',
  'admin.products.category.col-left.title': 'Thêm danh mục',
  'admin.products.category.col-left.parent.label': 'Thuộc danh mục',
  'admin.products.category.col-left.parent.empty': 'Trống',
  'admin.products.category.noti.delete.title': 'Xác nhận xoá danh mục!',
  'admin.products.category.noti.delete.description': 'Bạn chắc chắn muốn xoá danh mục này?',
  'admin.products.category.noti.delete.success': 'Xoá danh mục thành công!',
  'admin.products.category.noti.delete.failure': 'Xóa danh mục không thành công! Vui lòng thử lại sau.',
};

const color = {
  'admin.products.color.helmet': 'Màu sắc',
  'admin.products.color.col-left.title': 'Thêm màu sắc',
  'admin.products.color.col-left.group': 'Nhóm màu sắc',
  'admin.products.color.col-left.name': 'Tên màu sắc',
  'admin.products.color.col-left.code': 'Mã màu',
  'admin.products.color.col-left.placeholder.group': 'Đen',
  'admin.products.color.col-left.placeholder.name': 'Xám',
  'admin.products.color.col-left.placeholder.color': 'Tối đa 3 màu',
  'admin.products.color.noti.delete.title': 'Xác nhận xoá màu sắc!',
  'admin.products.color.noti.delete.description': 'Bạn chắc chắn muốn xoá màu sắc này?',
  'admin.products.color.noti.delete.success': 'Xoá màu sắc thành công!',
  'admin.products.color.noti.delete.failure': 'Xóa màu sắc không thành công! Vui lòng thử lại sau.',
};

const discount = {
  'admin.products.discount.helmet': 'Giảm giá',
  'admin.products.discount.col-left.title': 'Thêm giảm giá',
  'admin.products.discount.col-left.start': 'Ngày bắt đầu',
  'admin.products.discount.col-left.end': 'Ngày kết',
  'admin.products.discount.col-left.discount': 'Giá trị giảm',
  'admin.products.discount.col-left.active': 'Kích hoạt',
  'admin.products.discount.noti.delete.title': 'Xác nhận xoá giảm giá!',
  'admin.products.discount.noti.delete.description': 'Bạn chắc chắn muốn xoá giảm giá này?',
  'admin.products.discount.noti.delete.success': 'Xoá giảm giá thành công!',
  'admin.products.discount.noti.delete.failure': 'Xóa giảm giá không thành công! Vui lòng thử lại sau.',
};

const group = {
  'admin.products.group.helmet': 'Nhóm sản phẩm',
  'admin.products.group.add-title': 'Thêm nhóm',
  'admin.products.group.edit-title': 'Cập nhật nhóm',
  'admin.products.group.column.product': 'Sản phẩm',
  'admin.products.group.modal.action.product-info': 'Thông tin sản phẩm',
  'admin.products.group.modal.action.search-product': 'Tìm sản phẩm',
  'admin.products.group.noti.update.success': 'Cập nhật nhóm sản phẩm thành công',
  'admin.products.group.noti.delete.title': 'Xác nhận xoá nhóm sản phẩm!',
  'admin.products.group.noti.delete.description': 'Bạn chắc chắn muốn xoá nhóm sản phẩm này?',
  'admin.products.group.noti.delete.success': 'Xoá nhóm sản phẩm thành công!',
  'admin.products.group.noti.delete.failure': 'Xóa nhóm sản phẩm không thành công! Vui lòng thử lại sau.',
};

const label = {
  'admin.products.label.helmet': 'Nhãn',
  'admin.products.label.col-left.title': 'Thêm nhãn',
  'admin.products.label.noti.delete.title': 'Xác nhận xoá nhãn!',
  'admin.products.label.noti.delete.description': 'Bạn chắc chắn muốn xoá nhãn này?',
  'admin.products.label.noti.delete.success': 'Xoá nhãn thành công!',
  'admin.products.label.noti.delete.failure': 'Xóa nhãn không thành công! Vui lòng thử lại sau.',
};

const order = {
  'admin.products.order.helmet': 'Đơn hàng',
  'admin.products.order.column.code': 'Mã đơn',
  'admin.products.order.column.name': 'Tên khách',
  'admin.products.order.column.phone': 'Số điện thoại',
  'admin.products.order.column.total': 'Tổng tiền',
  'admin.products.order.column.status': 'Trạng thái',
  'admin.products.order.status.new': 'Đơn mới',
  'admin.products.order.status.processing': 'Đang xử lý',
  'admin.products.order.status.delivery': 'Đang giao hàng',
  'admin.products.order.status.delivered': 'Đã giao hàng',
  'admin.products.order.status.canceled': 'Đã hủy',
  'admin.products.order.modal.detail.header': 'Thông tin đơn hàng',
  'admin.products.order.modal.detail.code': 'Mã đơn',
  'admin.products.order.modal.detail.name': 'Tên khách hàng',
  'admin.products.order.modal.detail.phone': 'Số điện thoại',
  'admin.products.order.modal.detail.email': 'Email',
  'admin.products.order.modal.detail.region': 'Khu vực',
  'admin.products.order.modal.detail.address': 'Địa chỉ',
  'admin.products.order.modal.detail.note': 'Ghi chú',
  'admin.products.order.modal.detail.product.info': 'Thông tin sản phẩm',
  'admin.products.order.modal.detail.product.price': 'Đơn giá',
  'admin.products.order.modal.detail.product.quantity': 'Số lượng',
  'admin.products.order.modal.detail.product.color': 'Màu sắc',
  'admin.products.order.modal.detail.product.size': 'Kích cỡ',
  'admin.products.order.modal.detail.product.into-money': 'Thành tiền',
  'admin.products.order.modal.detail.product.total-money': 'Tổng tiền',
  'admin.products.order.noti.delete.title': 'Xác nhận xoá đơn hàng!',
  'admin.products.order.noti.delete.description': 'Bạn chắc chắn muốn xoá đơn hàng này?',
  'admin.products.order.noti.delete.success': 'Xoá đơn hàng thành công!',
  'admin.products.order.noti.delete.failure': 'Xóa đơn hàng không thành công! Vui lòng thử lại sau.',
};

const size = {
  'admin.products.size.helmet': 'Kích cỡ',
  'admin.products.size.col-left.title': 'Thêm kích cỡ',
  'admin.products.size.noti.delete.title': 'Xác nhận xoá kích cỡ!',
  'admin.products.size.noti.delete.description': 'Bạn chắc chắn muốn xoá kích cỡ này?',
  'admin.products.size.noti.delete.success': 'Xoá kích cỡ thành công!',
  'admin.products.size.noti.delete.failure': 'Xóa kích cỡ không thành công! Vui lòng thử lại sau.',
};

const style = {
  'admin.products.style.helmet': 'Kiểu dáng',
  'admin.products.style.col-left.title': 'Thêm kiểu dáng',
  'admin.products.style.noti.delete.title': 'Xác nhận xoá kiểu dáng!',
  'admin.products.style.noti.delete.description': 'Bạn chắc chắn muốn xoá kiểu dáng này?',
  'admin.products.style.noti.delete.success': 'Xoá kiểu dáng thành công!',
  'admin.products.style.noti.delete.failure': 'Xóa kiểu dáng không thành công! Vui lòng thử lại sau.',
};

const type = {
  'admin.products.type.helmet': 'Loại sản phẩm',
  'admin.products.type.col-left.title': 'Thêm loại sản phẩm',
  'admin.products.type.noti.delete.title': 'Xác nhận xoá loại sản phẩm!',
  'admin.products.type.noti.delete.description': 'Bạn chắc chắn muốn xoá loại sản phẩm này?',
  'admin.products.type.noti.delete.success': 'Xoá loại sản phẩm thành công!',
  'admin.products.type.noti.delete.failure': 'Xóa loại sản phẩm không thành công! Vui lòng thử lại sau.',
};

const weight = {
  'admin.products.weight.helmet': 'Cân nặng',
  'admin.products.weight.col-left.title': 'Thêm cân nặng',
  'admin.products.weight.noti.delete.title': 'Xác nhận xoá cân nặng!',
  'admin.products.weight.noti.delete.description': 'Bạn chắc chắn muốn xoá cân nặng này?',
  'admin.products.weight.noti.delete.success': 'Xoá cân nặng thành công!',
  'admin.products.weight.noti.delete.failure': 'Xóa cân nặng không thành công! Vui lòng thử lại sau.',
};

const product = {
  'admin.products.helmet': 'Tất cả sản phẩm',
  'admin.products.add-title': 'Thêm sản phẩm',
  'admin.products.edit-title': 'Chỉnh sửa sản phẩm',
  'admin.products.column.active': 'Trạng thái',
  'admin.products.column.author': 'Tác giả',
  'admin.products.column.time': 'Thời gian',
  'admin.products.column.slug': 'Đường dẫn',
  'admin.products.search.category.all': 'Tất cả danh mục',
  'admin.products.search.brand.all': 'Tất cả thương hiệu',
  'admin.products.search.type.all': 'Tất cả loại sản phẩm',
  'admin.products.search.style.all': 'Tất cả kiểu dáng',
  'admin.products.noti.delete.title': 'Xác nhận xóa sản phẩm!',
  'admin.products.noti.delete.description': 'Bạn có chắc muốn xóa sản phẩm này?',
  'admin.products.noti.delete.success': 'Xóa sản phẩm thành công!',
  'admin.products.noti.delete.failure': 'Xóa sản phẩm không thành công! Vui lòng thử lại sau.',
};

const form = {
  'admin.products.form.save.active': 'Lưu - Đăng bán',
  'admin.products.form.save.deactive': 'Lưu - Ngừng kinh doanh',
  'admin.products.form.content-box': 'Nội dung sản phẩm',
  'admin.products.form.title': 'Tên sản phẩm',
  'admin.products.form.slug': 'Liên kết tĩnh',
  'admin.products.form.code': 'Mã sản phẩm',
  'admin.products.form.description': 'Mô tả ngắn',
  'admin.products.form.content': 'Mô tả chi tiết',
  'admin.products.form.image': 'Hình ảnh',
  'admin.products.form.image-btn': 'Chọn hình ảnh',
  'admin.products.form.brand': 'Thương hiệu',
  'admin.products.form.category': 'Danh mục',
  'admin.products.form.color': 'Màu sắc',
  'admin.products.form.color.btn-add': 'Thêm màu',
  'admin.products.form.color.btn-image': 'Chọn ảnh',
  'admin.products.form.color.empty-image': 'Chưa chọn hình ảnh',
  'admin.products.form.label': 'Nhãn',
  'admin.products.form.price': 'Giá',
  'admin.products.form.price.discount': 'Giảm giá',
  'admin.products.form.price.add': 'Thêm giá',
  'admin.products.form.price.quantity': 'Số lượng',
  'admin.products.form.style': 'Kiểu dáng',
  'admin.products.form.type': 'Loại sản phẩm',
  'admin.products.form.weight': 'Cân nặng',
  'admin.products.form.size': 'Kích cỡ',
  'admin.products.form.gender': 'Giới tính',
  'admin.products.form.gender.men': 'Nam',
  'admin.products.form.gender.women': 'Nữ',
  'admin.products.form.gender.youth': 'Thiếu niên',
  'admin.products.form.gender.kid': 'Trẻ em',
  'admin.products.form.save.success': 'Đăng sản phẩm thành công',
  'admin.products.form.save.fail': 'Xảy ra lỗi, không thể đăng sản phẩm',
  'admin.products.form.error.prices': 'Vui lòng nhập số lượng và giá bán',
  'admin.products.form.error.prices.quantity': 'Số lượng phải tăng dần',
  'admin.products.form.error.colors': 'Vui lòng chọn màu và hình ảnh',
};

export default {
  ...brand,
  ...category,
  ...color,
  ...discount,
  ...group,
  ...label,
  ...order,
  ...size,
  ...style,
  ...type,
  ...weight,
  ...product,
  ...form,
};
